import React from "react";
import {Buffer} from 'buffer';

import "./projectDetail.scss";

class ProjectDetail extends React.Component {

    componentDidUpdate() {
        window.$('.carousel-widget').trigger('destroy.owl.carousel').removeClass('owl-loaded');
        window.$('.carousel-widget').find('.owl-stage-outer').children().unwrap();
        window.SEMICOLON.reactProjectReload.init();
    }

    render() {
        let preContent = '';
        let media = '';
        let statement = '';
        let content = '';
        let references = '';
        let bio1 = '';
        let bio2 = '';
        let bio3 = '';
        let bio4 = '';

        if (this.props.project.preContent) {
            preContent = Buffer.from(this.props.project.preContent, 'base64') + "<br />";
        }

        if (this.props.project.media) {
            media = Buffer.from(this.props.project.media, 'base64') + "<br />";
        }

        if (this.props.project.statement) {
            statement = Buffer.from(this.props.project.statement, 'base64') + "<br />";
        }

        if (this.props.project.content) {
            content = Buffer.from(this.props.project.content, 'base64') + "<br />";
        }

        if (this.props.project.references) {
            references = Buffer.from(this.props.project.references, 'base64') + "<br />";
        }

        if (this.props.project.bio1) {
            bio1 = Buffer.from(this.props.project.bio1, 'base64');
        }

        if (this.props.project.bio2) {
            bio2 = Buffer.from(this.props.project.bio2, 'base64');
        }

        if (this.props.project.bio3) {
            bio3 = Buffer.from(this.props.project.bio3, 'base64');
        }

        if (this.props.project.bio4) {
            bio4 = Buffer.from(this.props.project.bio4, 'base64');
        }

        return (
                <div className="single-post mb-0">
                    <div className="entry clearfix">

                        <div className="entry-title">
                            <h2>{this.props.project.title}</h2>
                        </div>

                        <div className="entry-meta">
                            <ul>
                                <li><i className="icon-calendar3"></i>{this.props.project.term} {this.props.project.year}</li>
                                <li><i className="icon-user"></i>{this.props.project.author}</li>
                            </ul>
                        </div>

                        <div className="entry-content mt-0">

                            <div className="pre-content" dangerouslySetInnerHTML={{__html: preContent}}></div>

                            <div className="media-content col-sm-9 mx-auto" dangerouslySetInnerHTML={{__html: media}}></div>

                            {statement && 
                                <div className="statement">
                                    <h3 className="art-statement">
                                        Artistic Statement
                                    </h3>
                                    <div dangerouslySetInnerHTML={{__html: statement}}></div>
                                </div>
                            }

                            <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>

                            <div className="references" dangerouslySetInnerHTML={{__html: references}}></div>

                            <br />

                            {this.props.project.bio1 &&
                                 <div className="card bio-card">
                                    <div className="card-header"><strong>About {this.props.project.author1}</strong></div>
                                    <div className="card-body">
                                        {this.props.project.bioSrc1 &&
                                            <div className="author-image">
                                                <img src={`/media/bio/${this.props.project.bioSrc1}`} alt={this.props.project.author1} className="rounded-circle" />
                                            </div>
                                        }

                                        <div className="bio" dangerouslySetInnerHTML={{__html: bio1}}></div>
                                    </div>
                                </div>
                            }

                            {this.props.project.bio2 &&
                                 <div className="card bio-card">
                                    <div className="card-header"><strong>About {this.props.project.author2}</strong></div>
                                    <div className="card-body">
                                        {this.props.project.bioSrc2 &&
                                            <div className="author-image">
                                                <img src={`/media/bio/${this.props.project.bioSrc2}`} alt={this.props.project.author2} className="rounded-circle" />
                                            </div>
                                        }

                                        <div className="bio" dangerouslySetInnerHTML={{__html: bio2}}></div>
                                    </div>
                                </div>
                            }

                            {this.props.project.bio3 &&
                                 <div className="card bio-card">
                                    <div className="card-header"><strong>About {this.props.project.author3}</strong></div>
                                    <div className="card-body">
                                        {this.props.project.bioSrc3 &&
                                            <div className="author-image">
                                                <img src={`/media/bio/${this.props.project.bioSrc3}`} alt={this.props.project.author3} className="rounded-circle" />
                                            </div>
                                        }

                                        <div className="bio" dangerouslySetInnerHTML={{__html: bio3}}></div>
                                    </div>
                                </div>
                            }

                            {this.props.project.bio4 &&
                                 <div className="card bio-card">
                                    <div className="card-header"><strong>About {this.props.project.author4}</strong></div>
                                    <div className="card-body">
                                        {this.props.project.bioSrc4 &&
                                            <div className="author-image">
                                                <img src={`/media/bio/${this.props.project.bioSrc4}`} alt={this.props.project.author4} className="rounded-circle" />
                                            </div>
                                        }

                                        <div className="bio" dangerouslySetInnerHTML={{__html: bio4}}></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
        );
    }
}

export default ProjectDetail;